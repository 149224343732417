body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

:root {
  --ck-z-default: 100000;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

/*header*/

.container-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 1px 0px 2px 1px #606060;
}

.img-services {
  width: 40px !important;
}

.container-login {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.container-search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-search-bar input {
  width: 100%;
  background: #213564;
  border-radius: 15px;
  border: none;
  padding: 7px 20px;
  color: #f8f9fa;
  outline: none;
}

.container-search-bar span {
  z-index: 30;
  margin-left: -33px;
  color: white;
}

.container-flex-login {
  width: 100%;
  background: #213564;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  color: #f8f9fa;
}

.container-logo {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
  height: 64px !important;
}

.container-acount {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

.login,
.create-count {
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.container-message {
  width: 45% !important;
}

.container-select-save-user {
  width: 40% !important;
}

.login {
  border-left: 1px solid white;
  margin-left: 15px;
  margin-right: 30px;
  justify-content: flex-start !important;
}

.create-count {
  justify-content: flex-end !important;
}

.login img:nth-child(1) {
  margin-left: 15px;
}

.create-count a:nth-child(2),
.login a:nth-child(2),
.login a:nth-child(3) {
  margin-left: 15px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: white;
  text-decoration: none;
  outline: none;
}

.container-sm-menu {
  display: none;
}

.container-main-manu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0px;
  margin-bottom: 30px;
}

.container-items-menu {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 50px;
}

.item-menu {
  text-decoration: none;
  color: #213564;
  font-weight: 600;
  font-size: 14px;
}

.item-menu:hover {
  color: #bfb7b2;
}

.search,
.search-sm {
  width: 20%;
  background: #213564;
  border-radius: 15px;
  border: none;
  padding: 7px 20px;
  color: #f8f9fa;
  outline: none;
}

/*.icon-search-1, .icon-search-2{
  position: inherit;
  margin-left: -80px;
  color: white;
  cursor: pointer;
}*/

.icon-search-2 {
  margin-left: -66px !important;
}

.current {
  color: #bfb7b2;
}

.currentDashboard {
  font-weight: 600;
}

.logo-main {
  margin-left: 40px;
}

.sm-menu-main {
  display: none;
}

/*footer*/
.container-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-main-footer {
  width: 100%;
  background: #213564;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f8f9fa;
}

.col-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 0px 0px 25px;
  margin: 50px 0px;
  height: 250px;
}

.logo-footer {
  width: 320px;
  display: flex;
  justify-content: flex-start;
}

.container-meet-us {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
}

.container-logo {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  height: 240px;
  width: 100%;
}

.container-map {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.map {
  background: white;
  width: 80%;
  height: 250px;
}

.line-vertical {
  background: #f8f9fa;
  width: 2px;
  height: 240px !important;
}

.title-footer {
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.line-horizontal {
  width: 75px;
  height: 5px;
  background: #f8f9fa;
  margin-top: 3px;
}

.container-info-us {
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-items: center;
}

.info-us {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.row-info-us {
  display: flex;
  align-items: center;
  width: 100%;
}

.info-meet-us {
  height: 195px;
  justify-content: space-around !important;
  align-items: stretch !important;
}

.container-social-media {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 35px;
}

.container-social-media span {
  width: 40%;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
}

.container-icons-social-media {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.container-address {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 15px;
  color: white;
}

.info-meet-us .info-us .row-info-us:nth-child(2) {
  margin-top: 25px;
}

.padding-left {
  padding-left: 15px;
}

.list-legales {
  height: 220px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.list-legales li {
  margin-left: -22px;
}

.list-legales li:nth-child(1) {
  margin-top: 10px;
}

.list-legales li:nth-child(2) {
  margin-top: 40px;
}

.list-legales li:nth-child(3) {
  margin-bottom: 40px;
}

.list-legales a {
  text-decoration: none;
  color: #f8f9fa;
}

.container-kairos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px !important;
  margin-left: 10px;
  height: 300px;
}

.container-kairos img {
  margin-left: 5px;
}

/*cgu, mentions y policy*/
.container-mentions-cgu-policy {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.row-mentions-cgu-policy {
  width: 80%;
  margin: 30px 0px;
}

.icon-item-acordion {
  text-align: justify;
}

.container-item-acordeon {
  text-align: justify;
  font-weight: 500;
}

.container-title-information {
  margin: 15px 0px;
}

.container-item-acordeon h5 {
  font-size: 16px;
  font-weight: 600;
}

.container-title-information {
  font-size: 16px;
  text-align: left;
  padding-left: 20px;
}

/*home*/
/*banner*/
.container-banner-main {
  background-size: 100% 500px;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-banner-main {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 0px 0px 95px;
}

.row-banner-main p {
  color: #f8f9fa;
  text-align: left;
  width: 650px;
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
}

.row-banner-main h1 {
  color: #f8f9fa;
  margin-bottom: -2px;
  font-size: 48px;
  letter-spacing: 3px;
}

.row-banner-main a {
  color: #f8f9fa;
  border: 2px solid #f8f9fa;
  text-decoration: none;
  padding: 10px 15px;
  font-weight: 600;
  margin-top: 15px;
  border-radius: 25px;
}

/*services*/
.container-services {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.container-title-services {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 0px;
  margin-bottom: 70px;
}

.container-item-services {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.fade .show {
  z-index: 1 !important;
}

#addModalUpdate {
  z-index: 10000;
}

.ck-balloon-rotator__content {
  z-index: -1 !important;
}

div.ck.ck-balloon-panel.ck-balloon-panel_arrow_n.ck-balloon-panel_visible.ck-balloon-panel_with-arrow {
  z-index: 10000 !important;
}

input#ck-labeled-field-view-e48d742183c25a6357937b0c2e14600eb.ck.ck-input.ck-input-text {
  z-index: 100000 !important;
}

.ck.ck-balloon-rotator::after {
  z-index: 10000 !important;
}

.container-title-services h3 {
  color: #213564;
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: 400;
}

.line-horizontal-center {
  background: #213564;
  height: 3px;
  width: 100px;
  margin-top: 0px;
}

.row-mentions-cgu-policy h3 {
  color: #213564;
  letter-spacing: 5px;
}

.icon-pdf {
  margin-left: 5px;
}

.icon-pdf a img {
  width: 60px !important;
}

.container-toolbar {
  background: #bfb7b2;
  display: flex;
  justify-content: space-around;
  height: 90px;
}

.container-option-toolbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.hiddenTooltip {
  display: none;
}

.visibleTooltip {
  display: block;
  position: absolute;
  width: 150px;
  font-size: 14px;
  color: white;
  background-color: black;
  margin-top: -70px;
  padding: 7px 5px;
  border-radius: 5px;
}

.container-toolbar-main {
  align-items: center;
  background: #eee;
  display: flex;
  grid-area: toolbar;
  justify-content: center;
  padding: 4px;
}

.side-bar-toolbar {
  position: absolute;
  left: 0;
  top: 300px;
  z-index: 100000;
}

.row-toolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.title-toolbar {
  width: 60%;
  color: white;
  font-size: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 3px;
  padding: 20px;
}

.pages-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zoom-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.full-screen-toolbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.image-update {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.container-update {
  text-align: justify;
}

.containerline-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.line-horizontal {
  background: #213564;
}

.container-title-info-services {
  display: flex;
  flex-direction: column;
}

.container-title-info-services h3 {
  margin-bottom: 5px;
}

.card-service {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 300px;
  margin: 15px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 1px #bfb7b2;
  padding: 10px 5px;
  height: 270px;
}

.container-img-services {
  border: 1px solid #213564;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-top: -50px;
  background: white;
}

.pack {
  padding: 15px 0px;
}

.container-img-services:hover {
  background: #213564;
}

.card-service h3 {
  font-weight: 500;
  color: #213564;
  letter-spacing: 3px;
}

.nom-prenom input {
  width: 70% !important;
}

.card-service p {
  color: #606060;
  font-size: 14px;
  padding: 0px 40px;
  margin-bottom: 30px;
}

/*collectif*/

.container-collectif {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.container-text-banner-sec {
  background-color: rgba(33, 53, 100, 0.3);
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-text-banner-sec div {
  margin-bottom: 20px;
}

.container-collectif h3,
.container-collectif div {
  font-size: 25px;
  letter-spacing: 5px;
}

.container-collectif div {
  font-weight: 600;
  letter-spacing: 3px;
}

/*updates*/

.container-updates {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-updates h3 {
  margin-bottom: 10px;
}

.container-carousel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.container-item-newsletter {
  width: 100%;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  margin-top: 100px;
}

.carousel {
  width: 90% !important;
  height: 530px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1 !important;
}

.carousel-items {
  height: 530px;
}

.container-main-post {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-post {
  width: 70%;
}

.container-title-post {
  width: 100%;
  font-size: 28px;
  margin-top: 40px;
  color: #213564;
  font-weight: 600;
  letter-spacing: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.line-horizontal-post {
  width: 100px;
  height: 5px;
  background: #213564;
}

.container-post h1 {
  color: #213564;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 3px;
}

.container-date-view-likes {
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-publishing {
  width: 100%;
  text-align: left;
  padding-left: 15px;
  color: rgba(191, 183, 178, 1);
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.container-date-view-likes .container-view-likes {
  width: 100%;
  justify-content: flex-end;
  margin-right: 30px;
}

.container-view-likes {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
}

.container-post .img-post {
  width: 500px;
  margin-bottom: 30px;
}

.container-post-description {
  padding: 15px;
}

.image-post {
  width: 400px;
  height: 300px;
}

.col-info-post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.col-info-post h1 {
  font-size: 30px;
}

.col-info-post h1,
.col-info-post p {
  padding-left: 20px;
  color: #213564;
}

.col-info-post p {
  text-align: left;
  width: 70% !important;
}

.rec-arrow-left,
.rec-arrow-right {
  background-color: #213564 !important;
  color: white !important;
  margin-right: 15px;
}

.rec-arrow-right {
  margin-right: 0px;
  margin-left: 10px;
}

.btn-newsletter {
  margin-bottom: 30px;
  margin-left: 20px;
}

/*subscription*/
.container-subscription {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-subscription h4 {
  color: #606060;
}

.container-form-subscription {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-form-subscription h5 {
  font-weight: 500;
  margin-bottom: 30px;
}

.form-subscription {
  width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.input-subcription {
  border: 2px solid #213564;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50px;
}

.input-subcription input {
  width: 100%;
  height: 20px;
  border: none;
  font-size: 16px;
  height: 46px;
}

.input-subcription input:focus,
.input-subcription input:active,
.input-subcription input:visited,
.input-subcription input:link {
  border: none;
  outline: none;
}

.form-subscription button {
  background: #213564 !important;
  border: none;
  color: white;
  width: 150px;
  height: 50px;
  font-size: 16px;
  padding: 10px 15px;
}

/*modal login*/
.container-modal,
.container-modal-member {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(33, 53, 100, 0.5);
}

.flex-container-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.close-modal {
  display: block;
  position: relative;
  bottom: -40px;
  right: -180px;
}

.close-icon {
  cursor: pointer;
  font-size: 30px;
  z-index: 1000;
}

.container-form-login,
.container-form-member {
  width: 400px;
  background: white;
}

.pdf-image {
  text-decoration: none;
}

.pdf-image p {
  background: rgba(191, 183, 178, 1);
  color: white;
  padding: 10px 15px !important;
  box-shadow: 0.5px 0.5px 1px 1px gray;
}

.pdf-image:hover {
  text-decoration: none;
  color: #213564;
}

.container-likes,
.container-view {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-image p {
  border-radius: 25px;
  margin-top: 5px;
}

.form-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: rgba(191, 183, 178, 0.2);
  width: 300px;
  margin-bottom: 30px;
}

.container-input input,
.inputs-member input {
  border: none;
  background: transparent;
  padding: 5px 15px;
  width: 100%;
  outline: none;
  height: 30px;
}

.form-login .container-title-services {
  margin-bottom: 30px;
}

.eye-password {
  cursor: pointer;
}

.sentence-pass-hidden {
  display: none;
}

.sentence-pass {
  text-align: center !important;
  padding: 5px 10px;
  margin-bottom: 10px;
  color: gray;
}

.btn-reset-password {
  margin-bottom: 50px;
}

.btn-color-blue,
.btn-color-gray {
  background: #213564;
  border: none;
  padding: 10px 30px;
  color: white;
  box-shadow: 1px 1px 5px 1px #bfb7b2;
}

.form-login a {
  color: #213564;
  text-decoration: none;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  width: 300px;
  font-style: italic;
}

.container-register-member {
  border: 1px solid #213564;
  margin: 30px;
}

.container-register-member h4 {
  color: #213564;
  font-weight: 500;
  letter-spacing: 3px;
}

/*datatable*/
.pagination li {
  display: none;
}

.pagination li:nth-child(1) {
  display: flex;
  width: 30px;
  height: 30px;
  font-family: "Concert One", cursive;
  font-size: 30px;
  border-radius: 100%;
  background: #213564;
  color: white;
  margin-top: 15px;
  margin-left: 40px;
}

.pagination li:nth-child(1) a,
.pagination li:last-child a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
}

.pagination li:last-child {
  display: flex;
  width: 30px;
  height: 30px;
  font-family: "Concert One", cursive;
  font-size: 30px;
  border-radius: 100%;
  background: #213564;
  color: white;
  margin-top: 15px;
  margin-left: 20px;
}

.column-datatable {
  background: #213564;
  width: 100%;
  color: white;
  height: 50px;
  border-right: 2px solid white;
}

.container-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  margin: 30px 0px;
  margin-right: 30px;
}

/*modal member*/

.container-modal-member .correct,
.container-modal-member .error {
  width: 90%;
}

.container-form-member {
  width: 70%;
}

.close-modal-member {
  display: flex;
  justify-content: flex-end;
  width: 69%;
  margin-bottom: -40px;
}

.container-inputs-member {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-inputs-member {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.inputs-member,
.input-text-area {
  border: none;
  background: rgba(191, 183, 178, 0.2);
  width: 90%;
  margin-bottom: 30px;
}

.input-text-area,
.genre-input {
  width: 95% !important;
}

.select-member {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: 0.4em 1.4em 0.3em 0.8em;
  width: 400px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 20px auto;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.03);
  border-radius: 0.3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  margin-bottom: 30px;
}

/*contact*/

.container-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-contact .correct,
.container-contact .error {
  margin-top: 1px;
}

.container-validation-lg {
  display: flex;
}

.container-validation-sm {
  display: none;
}

.container-map-contact {
  width: 100%;
  height: 100%;
}

.row-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  background: white;
  box-shadow: 1px 1px 5px 1px #bfb7b2;
  margin: 50px 0px;
}

.container-form {
  width: 100%;
  padding: 0px 15px;
}

.container-phone-function {
  display: flex;
}

.container-phone-function .container-input:nth-child(2) {
  margin-left: 5px;
}

.container-form p {
  color: #606060;
  margin-bottom: 30px;
}

.container-form .container-title-services {
  margin-bottom: 10px;
}

.container-form .container-input {
  margin-bottom: 15px;
  width: 100%;
}

.container-input textarea {
  border: none;
  width: 100%;
  outline: none;
  background: transparent;
  padding: 15px;
}

.container-input-checkbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 13px;
  margin: 0px 10px;
}

.container-input-checkbox #newsletter,
.container-input-checkbox #politique {
  display: none;
  margin-left: 5px;
}

.container-input-checkbox #newsletter+label,
.container-input-checkbox #politique+label {
  font-weight: bold;
  line-height: 1em;
  color: #606060;
}

.container-input-checkbox #newsletter:checked+label,
.container-input-checkbox #politique:checked+label {
  color: #606060;
}

.container-input-checkbox #newsletter+label:before,
.container-input-checkbox #politique+label:before {
  content: "";
  width: 16px;
  height: 16px;
  float: left;
  border: 1px solid #bfb7b2;
  background: white;
}

.container-input-checkbox #newsletter:checked+label:before,
.container-input-checkbox #politique:checked+label:before {
  background-color: white;
  border: none;
}

.container-input-checkbox #newsletter:checked+label:after,
.container-input-checkbox #politique:checked+label:after {
  content: "";
  width: 10px;
  height: 3px;
  margin-left: -13px;
  margin-top: 5px;
  border: 2px solid #213564;
  float: left;
  border-right: 0;
  border-top: 0;
  transform: rotate(-55deg);
}

/*pdf*/
.container-main-pdf {
  margin: 50px 30px;
  box-shadow: 1px 1px 5px 1px #606060;
}

.container-viewer-pdf {
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  height: 610px;
}

.container-pages {
  background-color: #bfb7b2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-container-pdf {
  font-size: 24px;
  letter-spacing: 3px;
  color: white;
  margin-left: 30px;
}

.container-pages .pager {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.page {
  background: #213564;
  color: white;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previous button,
.next button {
  background: transparent;
  border: none;
  outline: none;
}

.container-utilities {
  width: 150px;
  display: flex;
  justify-content: space-around;
  margin-right: 30px;
}

.container-zoom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: white;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 30px;
}

.slider::-webkit-slider-thumb {
  background: #213564;
  border: none;
}

.slider::-moz-range-thumb {
  background: #213564;
  border: none;
}

.color-icon-white {
  color: white;
  font-size: 18px;
}

.container-zoom button {
  border: none;
  background: transparent;
  outline: none;
}

.md-lg-password {
  display: none;
}

.sm-password {
  display: block;
}

/*dashboard*/
.container-console {
  margin: 50px 30px;
  box-shadow: 1px 1px 5px 1px #606060;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-body-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-button-img-notification {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
}

.container-button-img-notification img {
  margin-bottom: -15px;
}

.view-notification {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.container-button-img-notification a {
  border: none;
  background: white;
  color: #213564;
  padding: 7px 15px;
  text-decoration: none;
}

.container-presentation-notification {
  width: 90%;
  text-align: right;
}

.container-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  background: #213564;
  color: white;
  height: 200px;
  width: 230px;
  border-radius: 25px 25px 0px 25px;
}

#addModalNotification .row-add-user {
  justify-content: space-between;
}

.container-select-add-notification {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.container-select-add-notification:nth-child(1) {
  margin-right: 50px;
}

.container-select-add-notification .container-validation-lg,
.input-date-notification .container-validation-lg,
.input-date-notification .container-validation-lg .error {
  width: 100%;
}

.select-add-notification {
  height: 40px;
  margin: 0px;
  width: 100%;
  border: 1px solid #dddddd;
}

.container-input-date-notification {
  display: flex;
  height: 40px;
  width: 100%;
  border: 1px solid #dddddd;
  align-items: center;
}

.input-date-notification {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.input-date-notification span {
  width: 30%;
}

.input-date-notification input {
  width: 75%;
  outline: none;
}

.input-date-notification:nth-child(1) {
  margin-right: 50px;
}

.input-date-notification:nth-child(2) {
  padding-right: 3px;
}

.item-menu-dashboard {
  margin-bottom: -20px;
  border-top: none !important;
}

.item-menu-dashboard:nth-child(1) {
  border-top: 1px solid rgba(33, 53, 100, 0.6) !important;
}

.container-title-dashboard {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background: #bfb7b2;
  color: white;
}

.container-title-dashboard h1 {
  padding: 0px 30px;
}

.container-table-menu-dashboard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/*menu dashboard*/
.menu-dashboard {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 45px;
}

.line-vertical-dashboar {
  width: 2px;
  height: 400px;
  margin-top: 40px;
  background: #213564;
  margin-right: 20px;
  margin-left: -60px;
}

.container-table {
  width: 100%;
  margin-top: 12px;
  margin-right: 30px;
}

.menu-dashboard a {
  width: 90%;
  text-decoration: none;
  color: #213564;
  margin-bottom: 15px;
  padding: 15px 0px;
  padding-left: 5px;
  text-align: left;
  border-bottom: 1px solid rgba(33, 53, 100, 0.6);
  border-top: 1px solid rgba(33, 53, 100, 0.6);
}

.column-datatable:nth-child(1),
.table-services .column-datatable:nth-child(1) {
  width: 12%;
}

.table-notification .column-datatable:nth-child(1),
.table-notification .column-datatable:nth-child(2) {
  width: 25%;
}

.table-notification .column-datatable:nth-child(5) {
  width: 5%;
}

.column-datatable:nth-child(2) {
  width: 30%;
}

.table-services .column-datatable:nth-child(2) {
  width: 5%;
}

.column-datatable:nth-child(3) {
  width: 15%;
}

.table-services .column-datatable:nth-child(3) {
  width: 10%;
}

.column-datatable:nth-child(4) {
  width: 10%;
}

.column-datatable:nth-child(5) {
  width: 12%;
}

.column-datatable:nth-child(6) {
  width: 10%;
}

.column-datatable:nth-child(7) {
  width: 20%;
}

.row-table-services,
.row-table-updates {
  width: 100%;
}

.table-updates .column-datatable:nth-child(1) {
  width: 10%;
}

.table-updates .column-datatable:nth-child(2) {
  width: 7%;
}

.table-updates .column-datatable:nth-child(4) {
  width: 20%;
}

.table-updates .column-datatable:nth-child(5),
.table-updates .column-datatable:nth-child(6),
.table-updates .column-datatable:nth-child(9) {
  width: 5% !important;
}

.table-updates .column-datatable:nth-child(8) {
  width: 50px !important;
}

.table-updates .column-datatable:nth-child(7) {
  width: 300px;
}

.table-updates .column-datatable:nth-child(9) {
  width: 200px !important;
}

.table-updates .column-datatable:nth-child(5) {
  width: 30%;
}

.table-services,
.table-users,
.table-notification .table-updates {
  width: 100%;
}

.table-services .column-datatable:nth-child(4) {
  width: 15%;
}

.table-services .column-datatable:nth-child(5),
.table-services .column-datatable:nth-child(6),
.table-services .column-datatable:nth-child(9) {
  width: 5%;
}

.table-services .column-datatable:nth-child(7) {
  width: 5%;
}

.table-services .column-datatable:nth-child(8) {
  width: 130px !important;
}

.table-services .column-datatable:nth-child(10) {
  width: 150px !important;
}

.table-updates,
.table-services {
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 30px;
  margin-top: 7px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #213564;
  opacity: 1;
}

input:focus+.slider {
  box-shadow: 0 0 1px #213564;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.container-row-table td {
  padding: 2px 2px;
}

.container-row-table td:nth-child(7) {
  padding: 2px 0px;
}

.container-buttons {
  display: flex;
  justify-content: space-between;
}

.save {
  border: none;
  background: #213564;
  border-radius: 7px;
}

.row-add-user {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.input-add-user {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #dddddd;
  padding: 5px;
  width: 100%;
  margin-right: 5px;
}

.input-url {
  width: 83% !important;
}

#addModalUpdate .container-buttons {
  width: 100%;
  margin-top: 30px;
}

.input-add-user input {
  width: 100%;
  outline: none;
}

.input-occupation input {
  width: 65%;
}

.input-occupation-create-user input {
  width: 80%;
}

.input-add-user span {
  margin-right: 5px;
  color: #213564;
}

.input-add-user input[type="date"],
.input-add-user input[type="time"] {
  color: gray;
}

.input-add-user input[type="radio"] {
  border: 1px solid black !important;
  margin: 0px 5px;
}

.radio-button-user {
  border: none;
}

.modal {
  bottom: 0;
  right: 0;
  margin: auto;
}

.modal-header {
  background: #bfb7b2;
  color: white;
}

.adjust-size-modal {
  display: block;
  width: 160% !important;
  margin-left: -150px;
  margin-top: 100px;
}

.close-modal-add-user {
  opacity: 1;
}

.container-table {
  width: 100%;
}

.container-menu {
  width: 40%;
  margin-right: 0px;
}

.row-table {
  width: 100%;
  margin-top: 30px;
  overflow-x: scroll;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

.row-table::-webkit-scrollbar {
  -webkit-appearance: none;
}

.row-table::-webkit-scrollbar:vertical {
  width: 10px;
}

.row-table::-webkit-scrollbar-button:increment {
  display: none;
}

.row-table::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

.row-table::-webkit-scrollbar-thumb {
  background-color: #bfb7b2;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.row-table::-webkit-scrollbar-track {
  border-radius: 10px;
}

.row-table-updates,
.row-table-services {
  width: 100%;
}

.error,
.correct,
.warning {
  width: 75%;
  margin-top: -30px;
  margin-bottom: 30px;
  color: red;
  text-align: left;
  font-size: 13px;
}

#addUserModal .correct,
#addUserModal .error,
#editModalUser .correct,
#editModalUser .error,
#addModalService .correct,
#addModalService .error,
#editModalService .correct,
#editModalService .error,
#addModalNotification .correct,
#addModalNotification .error,
#editModalNotification .correct,
#editModalNotification .error,
#addModalUpdate .correct,
#addModalUpdate .error,
#editModalUpdate .correct,
#editModalUpdate .error {
  margin-top: 0px;
  margin-bottom: 10px;
}

#viewModal {
  margin-top: 100px;
}

#addModalUpdate .correct,
#addModalUpdate .error {
  width: 83%;
}

.correct {
  color: green;
}

.image {
  display: flex;
  justify-content: center;
}

.container-image-update {
  width: 300px;
}

.btn-color-gray {
  background: gray;
}

/*services*/
.container-services-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-list-services {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.container-service {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 25px 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  border-radius: 25px;
  box-shadow: 1px 0px 1px 1px #dddddd;
}

.container-service .img-service {
  width: 30%;
}

.container-service .img-service img {
  width: 300px;
  height: 300px;
}

.options-services {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 70px;
  height: 70px;
}

.options-services div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #213564;
  cursor: pointer;
}

.options-services div:hover {
  background: #213564;
  color: white;
}

.options-services div span {
  font-size: 18px;
  padding: 0px 5px;
  text-align: left;
}

.container-date-service {
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
  letter-spacing: 3px;
  color: rgba(191, 183, 178, 1);
}

.options-services div img {
  margin-right: 5px;
}

.content-service {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  margin-left: 30px;
}

.content-service .container-title-likes-view {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.presentation-service {
  text-align: justify;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 1.7;
}

.button-more-service {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.button-more-service .btn-newsletter {
  margin: 0px;
}

.content-service .container-view-likes {
  justify-content: flex-end;
}

.content-service .title-service {
  width: 100%;
  text-align: left;
  color: #213564;
  font-size: 24px;
  font-weight: 600;
}

.notification-container {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #213564;
}

.notification-row {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #213564;
  z-index: 100;
  margin: 4px;
}

.notification-off,
.notification-on {
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  background: #213564;
  color: white;
  height: 200px;
  width: 230px;
  border-radius: 25px 25px 0px 25px;
}

.notification-on {
  display: flex;
}

.container-main-notification {
  position: fixed;
  z-index: 10000;
  bottom: 50px;
  right: 50px;
}

.close-notification {
  width: 90%;
  cursor: pointer;
  text-align: right;
  margin-bottom: 20px;
  font-size: 20px;
}

.container-main-pdf {
  height: 700px;
}

.container-pdfs-links {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-pdfs-links .link-pdf {
  background-color: white;
  color: #213564;
  width: 170px;
  margin-right: 5px;
  border: 1px solid #213564;
  border-radius: 25px;
  font-weight: 600;
  padding: 10px 5px;
}

.container-pdfs-links .link-pdf:hover {
  text-decoration: none;
}

.btn-full-screen,
.btn-zoom-out,
.btn-zoom-in,
.btn-prev,
.btn-next,
.btn-print,
.btn-download,
.btn-search {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.container-img-close {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn-print,
.btn-download,
.btn-search {
  background-color: #213564;
  color: white;
}

.btn-close,
.btn-close-change {
  position: absolute;
  height: 610px;
  z-index: 1;
  width: 17px;
  margin-left: 189px;
  background-color: #213564;
}

.btn-close .container-img-close img,
.btn-close-change .container-img-close img {
  width: 40px !important;
  position: absolute;
  left: -11px;
}

.btn-close-change {
  margin-left: 0px;
}

.container-tumbnails {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  overflow: auto;
  width: 15%;
  display: block;
}

.container-tumbnails-hidden {
  display: none;
}

.btn-zoom-out span,
.btn-zoom-in span {
  color: #213564;
}

.container-update-dashboard {
  width: 100%;
  text-align: justify;
}

.container-update-dashboard .image img {
  width: 300px;
}

.container-list-pdf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.container-update-dashboard h2,
.container-update-dashboard h3 .container-update-dashboard h4 {
  text-align: center;
}

.justify-content-user {
  justify-content: flex-start;
}

.width-user {
  width: 50%;
}

.input-add-user-date {
  width: 36%;
  text-align: left;
}

.container-buttons-pdf {
  margin-top: 15px;
  background: rgba(191, 183, 178, 0.2);
}

.container-buttons-pdf button {
  background: transparent;
  border: none;
}

.container-buttons-pdf button span {
  color: #757575;
}

.hidden-option-pdf {
  display: none !important;
}

.visible-option-pdf {
  display: flex;
}

.container-title-info-services h6 {
  color: rgb(33, 53, 100) !important;
  margin-top: 5px;
  padding: 0px 5px;
}

.container-radio-services div {
  display: flex;
}

.container-post-description {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.container-post-description .media iframe {
  width: 500px;
  height: 400px;
  margin: 15px 0px;
}

.button-video-view {
  margin: 10px 0px;
  margin-left: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.button-video-view a {
  background: rgba(191, 183, 178, 1) !important;
  padding: 10px 15px;
  color: white;
  border-radius: 25px;
}

.container-date-publication {
  display: block;
  position: absolute;
  margin-top: -170px;
  margin-left: 30px;
  width: 13%;
  height: 150px;
  z-index: 1000;
  background-color: white;
  color: #aaa;
}

.row-date-publication {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  width: 100%;
  text-align: left;
  padding: 15px;
}

.col-carousel {
  width: 35%;
}

.row-date-publication span:nth-child(1) {
  font-size: 28px;
  color: #213564;
}

.title-carousel-update {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
}

.title-carousel-update button {
  border: none;
  background-color: transparent;
  padding-left: 30px;
}

.title-carousel-update button a {
  font-size: 22px;
  color: #213564;
  text-align: left;
}

.col-carousel .image-post {
  padding: 20px 30px;
}

#viewPdfModalService .container-main-pdf .container-tumbnails {
  width: 19.7%;
}

.container-form-update-pdf {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 60% !important;
}

.container-form-update-pdf button {
  margin-left: 30px;
}

.container-service .img-service img,
.container-post .img-post {
  border-radius: 25px !important;
}

.width-img,
.width-img img {
  width: 400px !important;
}

.banner-sec-home {
  margin-bottom: 25px;
  font-family: "Nunito";
  font-size: 50px !important;
}

.container-HDS {
  text-align: justify;
  line-height: 1.7;
}

.row-HDS p {
  -webkit-text-decoration-line: underline;
  /* Safari */
  text-decoration-line: underline;
}

.container-bt p {
  -webkit-text-decoration-line: none !important;
  /* Safari */
  text-decoration-line: none !important;
  margin: 1px;
}

.paragraph-bt {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.container-bt {
  width: 100%;
}

.container-bt-logo img {
  width: 200px;
}

@media (max-width: 1350px) {
  .container-main-manu {
    flex-direction: column;
    margin-top: 0px;
    margin-top: 45px;
  }

  .container-items-menu {
    width: 80%;
    margin: 60px 0px;
    margin-bottom: 10px;
    padding-right: 0px;
    justify-content: space-around;
  }

  .row-table-service,
  .row-table-updates {
    width: 800px !important;
  }

  .table-services,
  .table-updates,
  .table-users,
  .table-notification {
    width: 1100px;
  }

  .table-updates {
    width: 1400px !important;
  }

  .container-table-service,
  .container-table-udpates {
    width: 75%;
    display: flex;
    justify-content: center;
  }

  .container-menu {
    width: 30%;
  }

  .line-vertical-dashboar {
    margin-left: -20px;
  }

  .title-toolbar {
    width: 100%;
  }

  .container-tumbnails {
    width: 20%;
  }

  .btn-close {
    margin-left: 225px;
  }

  .btn-close-change {
    margin-left: 0px;
  }

  .options-services div img {
    width: 60px !important;
  }

  #viewPdfModalService .container-main-pdf .container-tumbnails {
    width: 21%;
  }
}

@media (max-width: 1250px) {

  .row-table-services,
  .row-table-updates {
    width: 750px !important;
  }

  .table-services,
  .table-updates,
  .table-users,
  .table-notification {
    width: 1100px;
  }

  .container-table-service,
  .container-table-updates {
    width: 70%;
  }

  .container-menu {
    width: 30%;
  }

  .line-vertical-dashboar {
    margin-left: -10px;
  }

  .container-tumbnails {
    width: 20%;
  }

  .btn-close {
    margin-left: 200px;
  }

  .btn-close-change {
    margin-left: 0px;
  }

  /*services*/
  .title-service {
    margin-top: 25px;
  }

  .container-service {
    flex-direction: column;
  }

  .img-service {
    width: 100% !important;
  }

  /*header*/
  .container-logo {
    width: 80%;
  }

  .container-date-publication {
    width: 20%;
  }

  #viewPdfModalService .container-main-pdf .container-tumbnails,
  #viewPdfModalService .container-main-pdf .btn-close {
    display: none;
  }

  .card-service {
    margin-bottom: 30px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .container-main-manu {
    flex-direction: column;
    margin-top: 0px;
    margin-top: 45px;
  }

  .container-items-menu {
    width: 80%;
    margin: 30px 0px;
    padding-right: 0px;
    justify-content: space-around;
  }

  .icon-search-2 {
    margin-left: -55px !important;
  }

  .logo-main {
    margin-top: 20px;
    margin-left: 0px;
  }

  /*home*/
  .row-banner-main {
    padding-left: 15px;
    padding-right: 15px;
  }

  .row-banner-main p {
    width: 100%;
    text-align: justify;
  }

  /*services*/

  .container-services {
    margin-bottom: 0px;
  }

  .card-service {
    width: 250px;
    margin: 0px 5px;
    padding: 0px;
  }

  .card-service p {
    color: #606060;
    font-size: 14px;
    padding: 0px 10px;
    margin-bottom: 20px;
  }

  /*updates*/

  .carousel {
    width: 99% !important;
    height: 600px !important;
  }

  .carousel-items {
    height: 600px;
  }

  .image-post {
    width: 300px;
    height: 200px;
  }

  .col-info-post h1 {
    text-align: left;
    font-size: 30px;
  }

  .col-info-post h1,
  .col-info-post p {
    padding-left: 5px;
  }

  .col-info-post p {
    width: 100%;
  }

  .btn-newsletter {
    margin-left: 5px;
  }

  .rec-arrow-left,
  .rec-arrow-right {
    margin-right: 0px;
    margin-left: 0px;
  }

  /*contact*/
  .row-contact {
    flex-direction: column;
    width: 80%;
  }

  .container-form {
    width: 90%;
  }

  /*pdf*/

  .container-toolbar {
    flex-direction: column;
    height: 130px;
    justify-content: center;
    align-items: center;
  }

  .container-tumbnails {
    width: 20%;
  }

  .btn-close {
    margin-left: 165px;
  }

  .btn-close-change {
    margin-left: 0px;
  }

  .title-toolbar {
    justify-content: center;
  }

  .container-option-toolbar {
    width: 70%;
    margin-top: -15px;
  }

  .title-container-pdf {
    font-size: 22px;
    margin-left: 8px;
    margin-right: -35px;
  }

  .container-utilities {
    width: 80px;
    justify-content: space-between;
    margin-right: 5px;
  }

  .container-console {
    margin-top: 150px;
    width: 97%;
    margin: 5px;
  }

  .row-table {
    overflow-x: scroll;
    height: auto;
  }

  .container-table-menu-dashboard {
    flex-direction: column;
  }

  .container-menu {
    width: 100%;
  }

  .menu-dashboard {
    flex-direction: row;
    margin: 0px;
    width: 100% !important;
    font-size: 14px;
    padding: 0px;
    align-items: center;
    justify-content: center;
  }

  .item-menu-dashboard {
    padding: 0px;
    height: 60px;
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-main-dashboard {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .line-vertical-dashboar {
    display: none;
  }

  .row-table-services,
  .row-table-updates {
    width: 900px;
  }

  .table-services,
  .table-udpates,
  .table-users,
  .table-notification {
    width: 1100px;
  }

  .container-table-service,
  .container-table-udpates {
    width: 100%;
  }

  /*services*/
  .title-service {
    margin-top: 25px;
  }

  .container-service {
    flex-direction: column;
  }

  .img-service {
    width: 100% !important;
  }

  /*header*/
  .container-logo {
    display: flex;
    justify-content: center !important;
    width: 60%;
    padding-left: 30px;
    margin-bottom: 15px !important;
  }

  .container-items-menu {
    margin-top: 55px;
    margin-bottom: -10px !important;
    width: 100%;
  }

  .col-carousel {
    width: 500px;
  }

  .col-carousel .image-post {
    padding: 0px;
    width: 300px;
    height: 270px;
  }

  .container-date-publication {
    width: 20%;
    margin-top: -150px;
    margin-left: 100px;
  }

  .title-carousel-update button {
    font-size: 28px;
    padding-left: 100px;
  }

  .button-video-view {
    margin-left: 100px;
  }
}

@media (max-width: 950px) {

  /*footer*/
  .container-main-footer {
    flex-direction: column;
  }

  .col-footer {
    width: 80%;
    height: auto;
    margin: 30px 0px;
    padding: 0px;
    height: auto;
  }

  .container-meet-us {
    margin-top: -80px;
  }

  .container-meet-us:nth-child(1) {
    margin-bottom: 30px;
  }

  .container-logo {
    height: auto;
    margin-top: 0px;
    width: 30%;
  }

  .line-vertical {
    display: none;
  }

  .container-tumbnails {
    width: 20%;
  }

  .btn-close {
    margin-left: 155px;
  }

  .btn-close-change {
    margin-left: 0px;
  }

  .title-service {
    margin-top: 25px;
  }

  .container-service {
    flex-direction: column;
  }

  .img-service {
    width: 100% !important;
  }

  .img-services {
    width: 30px !important;
  }

  .container-services {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .container-console {
    margin-top: 150px;
    width: 100%;
  }

  .container-input-date-notification {
    margin-bottom: 15px;
  }

  .side-bar-toolbar {
    top: 300px;
  }

  .title-toolbar {
    display: none;
  }

  .container-main-pdf {
    margin-top: 130px;
  }

  .container-post .img-post {
    width: 400px;
  }

  .container-services-list {
    margin-top: 170px;
  }

  .container-services-list h1 {
    font-size: 28px;
  }

  .container-img-services {
    margin-bottom: 15px;
  }

  .container-post {
    width: 100%;
  }

  .container-service {
    flex-direction: column;
    width: 100%;
  }

  .container-service .img-service {
    width: 100%;
  }

  .container-select-add-notification {
    width: 100%;
  }

  .container-select-add-notification:nth-child(1) {
    width: 100%;
    margin-bottom: 15px;
  }

  .line-vertical-dashboar {
    display: none;
  }

  .container-main-dashboard {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .row-table {
    overflow-x: scroll;
    height: auto;
  }

  .container-table-menu-dashboard {
    flex-direction: column;
  }

  .container-menu {
    width: 100%;
  }

  .menu-dashboard {
    flex-direction: row;
    margin: 0px;
    width: 100% !important;
    font-size: 14px;
    padding: 0px;
    align-items: center;
    justify-content: center;
  }

  .item-menu-dashboard {
    padding: 0px;
    height: 60px;
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .adjust-size-modal {
    width: 100% !important;
    margin-left: 0px;
  }

  .row-add-user {
    flex-direction: column;
    margin-bottom: 0px;
  }

  .input-add-user {
    margin-bottom: 10px;
  }

  /*header*/
  .container-main-manu {
    display: none;
  }

  .container-flex-login {
    flex-direction: column;
    height: 120px !important;
    justify-content: center !important;
  }

  .container-login {
    justify-content: space-around;
    z-index: 10000 !important;
  }

  .container-logo {
    margin-top: -5px !important;
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 5px;
  }

  .container-acount {
    padding-bottom: 10px;
  }

  .login,
  .create-count {
    width: 100%;
    justify-content: center !important;
  }

  .login {
    margin-left: 0px;
    margin-right: 0px;
  }

  .login span:nth-child(1) {
    margin-left: 0px;
  }

  .create-count span:nth-child(2),
  .login span:nth-child(2) {
    margin-left: 5px;
  }

  .sm-password {
    display: none;
  }

  .md-lg-password {
    display: block;
  }

  .sm-menu-main {
    margin-top: 133px !important;
    outline: none;
    z-index: 10000 !important;
    background: white;
    height: 50px !important;
  }

  .container-search-icon-menu {
    margin-top: -15px !important;
    background-color: white;
  }

  .icon-menu-sm {
    font-size: 40px;
    cursor: pointer;
    margin-top: 15px;
  }

  .container-items-menu {
    flex-direction: column;
    justify-content: center;
    padding-right: 0px;
  }

  .logo-main {
    margin-top: 0px;
    margin-left: 0px;
  }

  .sm-menu-main {
    display: block;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    margin-top: 60px;
    outline: none;
    z-index: 10000 !important;
  }

  .visible-container-sm-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: white;
    z-index: 1;
  }

  .icon-search-1,
  .icon-search-2 {
    color: #f8f9fa;
    margin-left: -30px;
    padding-right: 0px;
    margin-top: 15px;
  }

  .icon-search-2 {
    margin-left: -25px !important;
  }

  .container-search-icon-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }

  .search-sm {
    margin-right: 0px;
    width: 70%;
    margin-top: 15px;
  }

  .container-search-sm {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-menu {
    margin-top: 30px;
    font-size: 18px;
  }

  /*footer*/
  .container-main-footer {
    flex-direction: column;
  }

  .col-footer {
    width: 80%;
    height: auto;
    margin: 30px 0px;
    padding: 0px;
    height: auto;
  }

  .container-meet-us {
    margin-top: -80px;
  }

  .container-meet-us:nth-child(1) {
    margin-bottom: 30px;
  }

  .container-logo {
    height: auto;
    margin-top: 50px;
  }

  .line-vertical {
    display: none;
  }

  .container-map {
    height: 300px;
    margin-bottom: 0px;
  }

  .map {
    margin-top: 20px;
    background: white;
    width: 100%;
    height: 250px;
  }

  /*home*/
  .container-banner-main {
    margin-top: 135px;
    background-size: 100% 500px;
    background-repeat: no-repeat;
    height: 500px;
  }

  .row-banner-main {
    width: 100%;
    padding: 0px 15px 0px 15px;
  }

  .row-banner-main p {
    width: 100%;
    text-align: justify;
    font-size: 16px;
  }

  .row-banner-main h1 {
    font-size: 32px;
    margin-top: -10px;
    text-align: left;
  }

  .row-banner-main a {
    padding: 10px 15px;
    margin-top: 5px;
    font-size: 14px;
  }

  /*services*/

  .container-services {
    margin-bottom: 0px;
  }

  .container-item-services {
    flex-direction: column;
    align-items: center;
  }

  .card-service {
    width: 250px;
    margin: 5px;
    padding: 0px;
    margin-bottom: 70px;
    margin-top: 35px;
  }

  .card-service p {
    color: #606060;
    font-size: 14px;
    padding: 0px 10px;
    margin-bottom: 20px;
  }

  .card-service h6 {
    font-size: 14px !important;
  }

  .title-service {
    margin-top: 25px;
  }

  /*collectif*/

  .container-collectif {
    background-size: 100% 900px;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
  }

  .container-text-banner-sec {
    height: 200px;
  }

  /*subscription*/

  .form-subscription {
    width: 90%;
  }

  /*updates*/

  .carousel {
    width: 99% !important;
    height: 900px !important;
    margin-top: -70px;
    margin-bottom: -70px;
  }

  .carousel-items {
    height: 900px;
  }

  .container-item-newsletter {
    flex-direction: column;
  }

  .image-post {
    width: 300px;
    height: 200px;
  }

  .col-info-post h1 {
    text-align: left;
    font-size: 30px;
  }

  .col-info-post h1,
  .col-info-post p {
    padding-left: 5px;
  }

  .col-info-post p {
    width: 100%;
  }

  .btn-newsletter {
    margin-left: 5px;
  }

  .rec-arrow-left,
  .rec-arrow-right {
    margin-right: 0px;
    margin-left: 0px;
  }

  /*contact*/
  .container-contact {
    margin-top: 70px;
  }

  .container-validation-lg {
    display: none;
  }

  .container-validation-sm {
    display: flex;
  }

  .row-contact {
    width: 90%;
    flex-direction: column;
  }

  .container-phone-function {
    flex-direction: column;
  }

  .container-form {
    width: 90%;
  }

  .container-input-checkbox #politique:checked+label:after {
    width: 10px;
    height: 3px;
    margin-left: 3px;
    margin-top: -11px;
    color: black;
  }

  /*modal member*/
  .container-inputs-member {
    flex-direction: column;
    width: 100%;
  }

  .container-modal-member {
    position: static;
    margin-top: 130px !important;
  }

  .container-modal-member .correct,
  .container-modal-member .error {
    margin-top: 2px;
  }

  .container-modal-member .container-select-save-user {
    width: 90% !important;
  }

  .container-form-member {
    width: 95%;
  }

  .select-member {
    width: 90%;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  .input-text-area,
  .genre-input {
    width: 90% !important;
    margin-bottom: 10px;
  }

  .inputs-member {
    margin-bottom: 10px;
  }

  .container-modal-member {
    margin-top: 40px;
  }

  .close-modal-member {
    width: 90%;
  }

  /*pdf*/
  .container-main-pdf {
    margin: 120px 5px 30px 5px;
    box-shadow: 1px 1px 5px 1px #606060;
  }

  .container-pages {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    padding: 30px 0px;
  }

  .title-container-pdf {
    margin-left: 0px;
  }

  .container-pages .pager {
    margin-left: -40px;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  .container-utilities {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    margin-bottom: 30px;
  }

  .container-utilities img {
    margin-right: 10px;
  }

  .container-zoom {
    margin-bottom: 20px;
  }

  .row-table-services,
  .row-table-updates {
    width: 700px;
  }

  .table-services,
  .table-updates,
  .table-users,
  .table-notification {
    width: 1100px;
  }

  .container-table-service,
  .container-table-updates {
    width: 100%;
  }

  .container-mentions-cgu-policy {
    margin-top: 130px;
  }

  .container-likes,
  .container-view {
    height: 40px;
    justify-content: flex-start;
    align-items: center;
  }

  .pdf-image img {
    padding-top: 0px !important;
  }

  .container-toolbar {
    flex-direction: column;
    height: 130px;
    justify-content: center;
    align-items: center;
  }

  .title-toolbar {
    justify-content: center;
    display: flex;
    margin: 0px;
    margin-bottom: 20px;
    padding: 0px;
  }

  .container-option-toolbar {
    width: 100%;
    flex-direction: row-reverse;
  }

  .container-tumbnails,
  .container-tumbnails-hidden,
  .btn-close,
  .btn-close-change {
    display: none;
  }

  .container-viewer-pdf {
    height: 570px;
  }

  .options-services div img {
    width: 50px !important;
  }

  .container-main-post,
  .container-contact {
    margin-top: 160px;
  }

  .container-home {
    margin-top: 50px;
  }

  .container-main-post h1 {
    font-size: 28px;
  }

  .container-pdfs-links {
    margin-top: 220px;
    margin-bottom: -80px;
  }

  .container-main-dashboard {
    margin-top: 50px;
  }

  .row-add-user {
    width: 100%;
    margin-bottom: 20px;
  }

  .input-url {
    margin-bottom: 0px;
    width: 100% !important;
  }

  .margin-right-user {
    margin-right: 0px !important;
  }

  .width-user {
    width: 100%;
  }

  .options-services {
    flex-direction: column;
    height: auto;
  }

  .options-services div {
    justify-content: space-between;
    padding: 5px 10px;
  }

  .options-services div span {
    font-size: 14px;
    padding: 0px 0px;
    justify-content: space-between;
    text-align: left;
    width: 80%;
  }

  .options-services div img {
    width: 40px !important;
  }

  .container-radio-services {
    flex-direction: column;
  }

  .container-radio-services div {
    justify-content: space-between;
    width: 100%;
  }

  .container-radio-services div span {
    width: 100%;
    text-align: left;
  }

  .container-radio-services div input {
    width: 30%;
  }

  #addUserModal,
  #editModalUser,
  #addModalService,
  #editModalService,
  #addModalNotification,
  #editModalNotification,
  #addModalUpdate,
  #editModalUpdate,
  #editPdfModalUpdate,
  #editPdfModalService {
    z-index: 100000;
  }

  #viewModalNotification,
  #viewModalService,
  #viewModalUpdate,
  #viewModalUser,
  #addPdfModalUpdate,
  #addPdfModalService,
  #deletePdfModalService,
  #deletePdfModalUpdate {
    z-index: 100000;
  }

  .container-post-description .media iframe {
    width: 300px !important;
    height: 300px;
  }

  .col-carousel {
    width: 400px;
  }

  .col-carousel .image-post {
    padding: 0px;
    width: 300px;
    height: 300px;
  }

  .container-date-publication {
    width: 40%;
    margin-top: -150px;
    margin-left: 50px;
  }

  .row-date-publication span {
    font-size: 16px;
  }

  .row-date-publication span:nth-child(1) {
    font-size: 24px;
    color: #213564;
  }

  .title-carousel-update button {
    padding-left: 55px;
  }

  .button-video-view {
    margin-left: 55px;
  }

  .width-img {
    width: 300px !important;
  }

  #editPdfModalUpdate .container-list-pdf,
  #editPdfModalService .container-list-pdf {
    flex-direction: column !important;
  }

  .container-form-update-pdf {
    flex-direction: column;
    margin: 5px 0px;
    justify-content: center;
  }

  .banner-sec-home {
    font-size: 40px !important;
  }

  .paragraph-bt {
    flex-direction: column;
  }

  .container-pdfs-links {
    flex-direction: column;
  }

  .container-pdfs-links .link-pdf {
    margin-bottom: 5px;
    margin-right: 0px;
  }

  .container-table-udpates {
    width: 100% !important;
  }
}

@media (max-width: 400px) {

  /*header*/
  .container-main-manu {
    display: none;
  }

  .container-banner-main {
    background-size: 100% 650px;
    height: 650px;
  }

  .container-login {
    justify-content: space-around;
    z-index: 1000 !important;
  }

  .container-post .img-post {
    width: 300px;
  }

  .login,
  .create-count {
    width: 100%;
  }

  .login {
    margin-left: 0px;
    margin-right: 0px;
  }

  .login span:nth-child(1) {
    margin-left: 0px;
  }

  .create-count span:nth-child(2),
  .login span:nth-child(2) {
    margin-left: 5px;
  }

  .sm-menu-main {
    display: block;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    margin-top: 120px !important;
    outline: none;
    z-index: 1000 !important;
  }

  .icon-menu-sm {
    font-size: 40px;
    cursor: pointer;
  }

  .visible-container-sm-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: white;
    z-index: 1;
  }

  .container-items-menu {
    flex-direction: column;
    justify-content: center;
    padding-right: 0px;
  }

  .logo-main {
    margin-top: 0px;
    margin-left: 0px;
  }

  .icon-search-1,
  .icon-search-2 {
    color: #f8f9fa;
    margin-left: -30px;
    padding-right: 0px;
    margin-top: 15px;
  }

  .icon-search-2 {
    margin-left: -25px !important;
  }

  .container-search-icon-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-sm {
    margin-right: 0px;
    width: 70%;
  }

  .container-search-sm {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-menu {
    margin-top: 30px;
    font-size: 18px;
  }

  /*kmrc*/

  .container-collectif {
    background-size: 100% 700px;
    width: 100%;
    height: 200px;
  }

  .container-text-banner-sec h3 {
    font-size: 24px;
    margin-top: 30px;
  }

  .container-text-banner-sec div {
    font-size: 18px;
  }

  /*login*/
  .container-form-login {
    width: 100%;
    margin-top: 0px;
  }

  .close-modal {
    right: -160px;
  }

  /*modal member*/

  .select-member {
    width: 310px;
  }

  .input-text-area {
    width: 300px;
  }

  .container-modal-member {
    margin-top: 20px;
    position: absolute;
    height: auto;
  }

  .close-modal-member {
    width: 90%;
  }

  .container-form-member .container-title-services {
    margin-bottom: 15px;
  }

  .container-form-member .container-title-services h3 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 16px;
  }

  .container-search-icon-menu {
    margin-top: 0px !important;
  }

  .container-form-subscription h5 {
    width: 97%;
  }

  .input-subcription img {
    z-index: -1;
    margin-left: 40px;
  }

  .container-logo,
  .container-meet-us {
    height: 220px;
  }

  .options-services div img {
    width: 35px !important;
  }

  .item-menu-dashboard:nth-child(1) {
    border-top: none !important;
  }

  .item-menu-dashboard {
    border-bottom: none !important;
  }

  .container-toolbar .title-toolbar {
    font-size: 24px;
    margin-top: 30px;
  }

  .container-option-toolbar {
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  .container-toolbar {
    height: auto;
  }

  .full-screen-toolbar {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .container-main-pdf {
    height: 820px;
  }

  .col-carousel {
    width: 100%;
  }

  .col-carousel .image-post {
    padding: 0px;
    width: 300px;
    height: 300px;
  }

  .container-date-publication {
    width: 55%;
    margin-top: -150px;
    margin-left: 0px;
  }

  .title-carousel-update button {
    padding-left: 0px;
  }

  .button-video-view {
    margin-left: 0px;
  }

  .width-img {
    width: 300px !important;
  }

  #addUserModal,
  #editModalUser,
  #addModalService,
  #editModalService,
  #addModalNotification,
  #editModalNotification,
  #addModalUpdate,
  #editModalUpdate,
  #editPdfModalUpdate,
  #editPdfModalService {
    margin-top: 0px !important;
  }

  #viewModalNotification,
  #viewModalService,
  #viewModalUpdate,
  #viewModalUser,
  #addPdfModalUpdate,
  #addPdfModalService,
  #deletePdfModalService,
  #deletePdfModalUpdate {
    margin-top: 0px !important;
  }

  .container-date-view-likes .container-view-likes {
    margin: 0px !important;
    justify-content: center !important;
  }
}

.little-padding {
  padding: 5px;
}

.lateral-medium-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.def-icon-gray {
  font-size: 24px;
  color: #aaa;
}

.def-icon-green {
  font-size: 24px;
  color: rgb(21, 128, 26);
}

.def-icon-blue {
  font-size: 24px;
  color: #203564;
}