#inputGroupFile {
    display: none;
}

.file-label {
    border: 1px solid #c0cde8;
    background-color: white;
    color: #203564;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 0%;
}

.file-button {
    border: 1px solid #203564;
    background-color: #203564;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 0%;
}

.icon-default {
    font-size: x-large;
}

.file-error {
    margin-bottom: 0%;
    padding: 1em;
    color: red;
    font-size: small;
}